import React from "react";
import { moderation } from './header.module.css'

const Version = () => {
    return (
        <div className={moderation}>
            <small>v2.0</small>
        </div>
    )
}

export default Version