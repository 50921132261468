import React from "react";
import { imgResponsive, url } from "./images.module.css"

const Image = ({data}) => {
    return (
        <>
            <figure>
                <img className={imgResponsive} data-image-url={data.publicURL} src={data.publicURL} alt={data.name} />
            </figure>
        </>
    )
}

export default Image