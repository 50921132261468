import React from "react";
import { russo, moderation } from './header.module.css'

const Header = () => {
    return (
        <div className={russo}>
            <h1>Yes, it's always time for VODKA !</h1>
            <p className={moderation}>
                <small>But with moderation</small>
            </p>
        </div>
    )
}

export default Header