import React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Header from "../components/header"
import Image from "../components/image"
import Version from "../components/version"

const Index = ({data}) => {
  var randomIndex = Math.floor(Math.random() * data.allFile.nodes.length)
  return (
    <>
      <Helmet>
        <title>Is it time for Vodka ?</title>

        <link rel="canonical" href="http://mysite.com/example" />

        <meta name="description" content="Disover if it's time for VODKA, or not yet. GIF style. For fun only, drink with moderation."/>

        <meta property="og:url" content=""/>
        <meta property="og:title" content="Is it time for Vodka ?"/>
        <meta property="og:site_name" content="Is it time for Vodka ?"/>
        <meta property="og:type" content="video.other"/>
        <meta property="og:image" content={data.allFile.nodes[randomIndex].publicURL}/>
        <meta property="og:image:type" content="image/gif"/>
        <meta property="og:image:width" content=""/>
        <meta property="og:image:height" content=""/>

        <meta name="rating" content="general"/>
        <meta name="image:rating" content="g"/>
       
        <link rel="icon" href="favicon.ico" sizes="16x16 32x32 48x48 64x64" type="image/vnd.microsoft.icon"/>
        <link href="https://fonts.googleapis.com/css?family=Russo+One" rel="stylesheet" type="text/css"/>
      </Helmet>
      <Header/>
      <Image data={data.allFile.nodes[randomIndex]}/>
      <Version/>
    </>
  )
}

export const query = graphql`
query {
  allFile(filter: {sourceInstanceName: {eq: "images"}}) {
    nodes {
      id      
      name
      publicURL
    }
  }
}
`

export default Index